import { AuthContextProvider } from './contexts/Auth';
import { Route, Routes } from 'react-router-dom';
import Page404 from './components/Page404';
import Home from './components/Home';
import ProtectedRoute from './contexts/ProtectedRoute'
import Account from './components/Account';



function App() {
    return (
        <>
            <AuthContextProvider>
                <Routes>
                    <Route path='/*' element={<Page404 />} />
                    <Route path='/' element={<Home />} />
                    <Route path='/Home' element={<Home />} />

                    <Route path='/Account' element={
                        <ProtectedRoute>
                            <Account />
                        </ProtectedRoute>
                    } />

                </Routes>
            </AuthContextProvider>

        </>
    );
}

export default App;
