import React, { useState, useEffect } from 'react';
import { UserAuth } from '../contexts/Auth';
// import { FaSignOutAlt } from "react-icons/fa";
import fireDb from '../firebase'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useNavigate, useParams } from "react-router-dom";


import '../assets/style/Account.css'



const Account = () => {

    const { logOut, user } = UserAuth();

    const handleSignOut = async () => {
        try {
            await logOut();
        } catch (error) {
            console.log(error);
        }
    };

    // Date
    var date = new Date().toLocaleDateString();
    var time = new Date().toLocaleTimeString();

    const initialState = {
        name: "",
        email: "",
        contact: "",
        photo: `${user?.photoURL}`,
        yourname: `${user?.displayName}`,
        dates: `${date}`,
        times: `${time}`
    }


    // post message 

    const [state, setState] = useState(initialState);
    const [data, setData] = useState({});

    const { name, email, contact, photo, yourname } = state


    const history = useNavigate();

    // Edit show data
    const { id } = useParams();
    useEffect(() => {
        fireDb.child('contacts').on('value', (snapshot) => {
            if (snapshot.val() !== null) {
                setData({ ...snapshot.val() })
            } else {
                setData({})
            }
        })
        return () => {
            setData({})

        }
    }, [id])
    useEffect(() => {
        if (id) {
            setState({ ...data[id] })
        } else {
            setState({ ...initialState })
        }
        return (() => {
            setState({ ...initialState })
        })
    }, [id, data])



    // Add & Update
    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setState({ ...state, [name]: value });

    };


    const handleSubmit = (e) => {
        e.preventDefault();
        if (!name) {
            toast.error("please check input field");
        } else {
            if (!id) {
                fireDb.child("contacts").push(state, (err) => {
                    if (err) {
                        toast.error(err);
                    } else {
                        toast.success("Added Successfully");
                        handleSignOut();
                        window.alert("Thank you for your review");
                        history('/');
                    }
                });
            } else {
                fireDb.child(`contacts/${id}`).set(state, (err) => {
                    if (err) {
                        toast.error(err);
                    } else {
                        toast.success("Updated Successfully");
                        handleSignOut();
                        window.alert("Thank you for your review");
                        history('/');
                    }
                });
            }
        }
    };


    // Call data from firebase show on table

    useEffect(() => {
        fireDb.child('contacts').on('value', (snapshot) => {
            if (snapshot.val() !== null) {
                setData({ ...snapshot.val() })
            } else {
                setData({})
            }
        })
        return () => {
            setData({})
        }
    }, [])


    // Date

    // const date = new Date();
    // const result = date.toLocaleDateString('th-TH', {
    //     year: 'numeric',
    //     month: 'long',
    //     day: 'numeric',
    //     weekday: 'long',
    // })



    return (

        <>
            <ToastContainer
                position="top-center"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
            />

            <div className="container_account_wrapper">

                <div className="profile_user">
                    <div className="wrappers">
                        <img className='user_image' src={user?.photoURL} value={photo} alt="" />
                        <div className="wrapper_name">
                            <p value={yourname}>{user?.displayName}</p>
                            <button onClick={handleSignOut} className='logout'>
                                Logout
                            </button>
                        </div>
                    </div>
                </div>
                <form className='form_input' onSubmit={handleSubmit}>
                    {/* <label htmlFor="name">Name</label> */}
                    <input className='input' type="text" id='name' name='name' placeholder={`ใส่คำรีวิวของคุณ ${user?.displayName}`} value={name || ""} onChange={handleInputChange} />
                    <input className='save_btn' type="submit" value={id ? "Update" : "โพสต์รีวิว"} />
                </form>
            </div>



            {/* <div className="container">
                <div className="main_box">
                    {Object.keys(data).map((id, index) => {
                        return (
                            <div className='message_box' key={id}>

                                <div className="wrapper inbox">
                                    <img className='user_image' src={data[id].photo} alt="" />
                                    <div className="wrapper_info">
                                        <p className='name_inbox'>{data[id].yourname}</p>
                                    </div>
                                </div>

                                <p className='number' scope='row'>{index + 1}</p>
                                <p>{data[id].name}</p>

                                <div className="date_time">
                                    <p>{data[id].dates}</p>
                                    <p>{data[id].times}</p>
                                </div>

                            </div>
                        )
                    })}
                </div>
            </div> */}
        </>



    );
};

export default Account;
