import React from 'react'

const Page404 = () => {
    return (
        <>
            <div className="page_error">
                <h1>404</h1>
                <p>404 Error Page Not Found</p>
            </div>
        </>

    )
}

export default Page404