import React from 'react'
import { Navigate } from 'react-router-dom'
import { UserAuth } from '../contexts/Auth'

const ProtectedRoute = ({ children }) => {

    const { user } = UserAuth();
    if (!user) {
        return <Navigate to='/Home' />;
    }

    return (
        children
    )
}

export default ProtectedRoute