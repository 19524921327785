
import '../assets/style/App.css'
import '../assets/style/SalePage.css'
import React, { useState } from 'react';
import { useNavigate, NavLink } from 'react-router-dom'


const NavbarSide = () => {


    const [menuState, setMenuState] = useState(false);

    const handleToggleMenu = () => {
        setMenuState(!menuState);
    };

    const handleSelectClick = () => {
        setMenuState(!menuState);
    };
    return (
        <>
            <div>
                <div className={`toggle ${menuState ? 'active_toggle' : ''}`} onClick={handleToggleMenu}>
                    <div className="wrapper_toggle">
                        <span></span>
                        <span></span>
                        <span></span>
                    </div>
                </div>
                <div className={`nav_menu ${menuState ? 'active_nav' : ''}`}>
                    <div className={`wrapper_nav ${menuState ? 'active_nav' : ''}`}>
                        <ul>
                            <li>
                                <NavLink className='NavLink' to='/'>หน้าเเรก</NavLink>
                            </li>
                            <li>
                                <NavLink className='NavLink' to='/CustomerPage'>เลขพัสดุ</NavLink>
                            </li>
                            <li>
                                {/* <NavLink className='NavLink' to='/CustomerPage'>เพจเฟสบุ๊ค</NavLink> */}
                                <a className='NavLink' target='bank' href="https://www.facebook.com/profile.php?id=61550681831668&mibextid=LQQJ4d">เพจเฟสบุ๊ค</a>
                            </li>
                        </ul>
                    </div>

                </div>
            </div>
            {/* <div className="toggle toggle_hover cursor_hover ">
                <div className="wrapper_toggle">
                    <span></span>
                    <span></span>
                    <span></span>
                </div>
            </div>
            <div className="nav_menu">
                <div className="wrapper_nav">
                    <ul>
                        <li className="active cursor_hover"><a class="select " href="#home">Home</a></li>
                        <li><a className="select cursor_hover" href="#about">About</a></li>
                        <li><a className="select cursor_hover" href="#portfolio">Portfolio</a></li>
                        <li><a className="select cursor_hover" href="#blog">Blog</a></li>
                        <li><a className="select cursor_hover" href="#contact">Contact</a></li>
                    </ul>
                </div>
                <div className="media">
                    <ul>
                        <li className="magic-hover"><a href="#">Facebook</a></li>
                        <li className="magic-hover"><a href="#">Twitter</a></li>
                        <li className="magic-hover"><a href="#">Instagram</a></li>
                    </ul>
                </div>
            </div> */}

        </>
    )
}

export default NavbarSide