import React,{useState} from 'react'
import lock from '../assets/images/lock.svg'
import { FaGoogle } from "react-icons/fa";
// import ClickAwayListener from '@mui/material/ClickAwayListener';
import '../assets/style/App.css'



const Modal = ({ signin, modalset, onclickFuc, setModal }) => {

    // const alertForget = () =>{
    //     alert('ลืมก็ช่างหัวมึงเหอะ ว๊ายยยๆๆ')
    // }
    return (
        <>
            <div className={`modal_box ${modalset ? 'modal_box_active' : ''}`}>
                <div className="close" onClick={onclickFuc}>
                    X
                </div>
                <div className="lock_box">
                    <img className='lock_img' src={lock} alt="" />
                </div>
                <h3>กรุณาล็อคเพื่อให้รีวิว</h3>

                <button className='sign_in' onClick={signin}>{<FaGoogle fontSize={30} />}Sign In With Google</button>
                {/* <p className='forget_pass' onClick={alertForget}>ลืม Password</p> */}
            </div>
        </>

    )
}

export default Modal