import firebase from 'firebase/compat/app';
import 'firebase/compat/database';
import "firebase/compat/auth";

var firebaseConfig = {
  apiKey: "AIzaSyCG2Yxxhu6QS03Q9oxptnzar6NcTKNndO0",
  authDomain: "react-hosting-572d8.firebaseapp.com",
  databaseURL: "https://react-hosting-572d8-default-rtdb.asia-southeast1.firebasedatabase.app",
  projectId: "react-hosting-572d8",
  storageBucket: "react-hosting-572d8.appspot.com",
  messagingSenderId: "498608759806",
  appId: "1:498608759806:web:ccfb83619adac4e96516af"
};

firebase.initializeApp(firebaseConfig);
const firebaseApp = firebase.initializeApp(firebaseConfig);
const fireDb = firebaseApp.database().ref();


const provider = new firebase.auth.GoogleAuthProvider();
provider.setCustomParameters({prompt: 'select_account'})

export const signInWithGoogle = () => auth.signInWithPopup(provider)
export const auth = firebase.auth()
export default fireDb






// const fireDb = firbase.initializeApp(firebaseConfig);
// export default fireDb.database().ref();


