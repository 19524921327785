import React, { useState, useEffect } from 'react';
import { UserAuth } from '../contexts/Auth';
import fireDb from '../firebase'
// import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useNavigate, useParams } from "react-router-dom";
import Modal from './Modal';

import '../assets/style/App.css'
import background from '../assets/images/img1.jpg'
import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/pagination';


// import required modules
import { Autoplay, Pagination, Navigation } from 'swiper/modules';


import NavbarSide from './NavbarSide';
import logo from '../assets/images/new-sss2.png'
import { FaGrinBeam } from "react-icons/fa";

const Home = () => {

    const { logOut, user, googleSignIn } = UserAuth();

    const handleSignOut = async () => {
        try {
            await logOut();
        } catch (error) {
            console.log(error);
        }
    };

    // Date
    var date = new Date().toLocaleDateString();
    var time = new Date().toLocaleTimeString();

    const initialState = {
        name: "",
        email: "",
        contact: "",
        photo: `${user?.photoURL}`,
        yourname: `${user?.displayName}`,
        dates: `${date}`,
        times: `${time}`
    }


    // post message 

    const [state, setState] = useState(initialState);
    const [data, setData] = useState({});

    const { name, email, contact, photo, yourname } = state




    // Edit show data
    const { id } = useParams();
    useEffect(() => {
        fireDb.child('contacts').on('value', (snapshot) => {
            if (snapshot.val() !== null) {
                setData({ ...snapshot.val() })
            } else {
                setData({})
            }
        })
        return () => {
            setData({})
        }
    }, [id])
    useEffect(() => {
        if (id) {
            setState({ ...data[id] })
        } else {
            setState({ ...initialState })
        }
        return (() => {
            setState({ ...initialState })
        })
    }, [id, data])


    // Click outside close modal
    // const [open, setOpen] = useState(false);


    // Click Show Modal

    const [modal, setModal] = useState(false)
    const ModalShow = () => {
        setModal(!modal)
    }
    // const handleClickAway = () => {
    //     setOpen(false);
    // };


    // When click form input popup sign in with google
    // const { googleSignIn, users } = UserAuth();
    const navigate = useNavigate();
    const handleGoogleSignIn = async () => {
        try {

            await googleSignIn();
        } catch (error) {
            console.log(error);
        }
    };

    useEffect(() => {
        if (user != null) {
            setTimeout(() => navigate("/Account"), 1000);

            navigate('/Account');
        }
    }, [user]);



    // Call data from firebase show on table
    useEffect(() => {
        fireDb.child('contacts').on('value', (snapshot) => {
            if (snapshot.val() !== null) {
                setData({ ...snapshot.val() })
            } else {
                setData({})
            }
        })
        return () => {
            setData({})
        }
    }, [])


    // Date

    // const date = new Date();
    // const result = date.toLocaleDateString('th-TH', {
    //     year: 'numeric',
    //     month: 'long',
    //     day: 'numeric',
    //     weekday: 'long',
    // })

    // window.scrollTo({
    //     top: 0,
    //     behavior: 'smooth'
    // });

    const [isVisible, setIsVisible] = useState(true);

    useEffect(() => {
        const timeout = setTimeout(() => {
            setIsVisible(false);
        }, 1500);

        return () => clearTimeout(timeout);
    }, []);





    return (

        <>
            <div className={`overlay ${modal ? 'overlay_active' : ''}`} onClick={ModalShow}></div>

            <Modal signin={handleGoogleSignIn} modalset={modal} onclickFuc={ModalShow} setModal={setModal} />

            <NavbarSide />

            <div className={isVisible ? 'logo' : 'move'}>
                <img src={logo} alt="โลโก้ก๋วยเตี๋ยวเรือสำเร็จรูปเข้าเส้น" />
            </div>

            {/*=======================  */}
            {/* Section one */}
            {/*=======================  */}
            <div className="section_one">
              

                <div className="section_one_overlay"></div>
                <h2 className='text-header-intro'
                    data-aos="fade-up"
                    data-aos-duration="1000"
                    data-aos-delay="1500"
                >เข้าเส้น</h2>
                <h2 className='text-header-intro-des'
                    data-aos="fade-up"
                    data-aos-duration="1500"
                    data-aos-delay="1500"
                >บ้านโป่ง</h2>
            </div>

            {/*=======================  */}
            {/* Section two */}
            {/*=======================  */}
            <div className="section_two">

                <h2 className='header_text'>เมนูเเนะนำ</h2>





            </div>


            <div className="section_three">
                <div className="input_review_for_signin">
                    <form className='form_input' >
                        {/* <input className='input' type="text" id='name' name='name' placeholder={`Say SomeThing`} /> */}
                        {/* <input className='save_btn' type="submit" value={id ? "Update" : "Post"} /> */}

                        <p className='save_btn save_btn2' onClick={ModalShow}>คลิกเพื่อรีวิว  <FaGrinBeam className='review-icon' /></p>

                    </form>
                </div>

                <Swiper
                    slidesPerView={1}
                    spaceBetween={10}
                    autoplay={{
                        delay: 2500,
                        disableOnInteraction: false,
                    }}
                    pagination={{
                        clickable: true,
                    }}
                    modules={[Autoplay, Pagination, Navigation]}
                    breakpoints={{
                        640: {
                            slidesPerView: 1,
                            spaceBetween: 20,
                        },
                        768: {
                            slidesPerView: 2,
                            spaceBetween: 40,
                        },
                        1024: {
                            slidesPerView: 3,
                            spaceBetween: 50,
                        },
                    }}

                    className="mySwiper"
                >
                    {Object.keys(data).map((id, index) => {
                        return (
                            <SwiperSlide>
                                <div className='message_box' key={id}>
                                    <div className="wrapper inbox">
                                        <img className='user_image' src={data[id].photo} alt="" />
                                        <div className="wrapper_info">
                                            {/* <p>{user?.email}</p> */}
                                        </div>
                                    </div>
                                    <div className="wrapper_info_review">
                                        <p className='name_inbox'>{data[id].yourname}</p>
                                        <p className='message_customer'>{data[id].name}</p>
                                    </div>
                                    <div className="date_time">
                                        <p>{data[id].dates}</p>
                                        <p>{data[id].times}</p>
                                    </div>

                                </div>
                            </SwiperSlide>
                        )
                    })}


                </Swiper>





            </div>



        </>



    );
};

export default Home;
